<template>
  <div class="H5strengthening">
    <navi-gation />
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <el-input
          v-model.trim="page.searchFileName"
          size="small"
          :placeholder="$t('AppManage.H5strengPage.placeholder')"
          @keyup.enter.native="onSearch"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" size="small" v-debounce:500="onSearch">{{
          $t("public.Inquire")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="0" class="mt15" style="display: flex; margin-left: -0.5px">
      <el-col :span="1.5">
        <el-upload
          class="upload-demo1"
          ref="upload"
          accept=".zip"
          :file-list="fileList"
          :action="'/sysFile/fileUp'"
          :on-change="uploadChange"
          :auto-upload="false"
        >
          <el-button class="upBtn" slot="trigger" size="small" type="primary">
            {{ $t("AppManage.H5strengPage.UploadFiles") }}
          </el-button>
        </el-upload>
      </el-col>
      <el-col :span="24">
        <el-button-group>
          <!-- <div class="stringBox"> -->
          <el-button
            style="
              border-radius: 0px !important;
              border-left: 1px solid #e4e4e6 !important;
              border-right: 1px solid #e4e4e6 ! !important;
            "
            size="small"
            @click="fastenMuch(delarr)"
            :type="typeInfo"
            :disabled="btnJiagu"
            >{{ $t("AppManage.H5strengPage.H5Reinforcement") }}</el-button
          >
          <el-button
            size="small"
            @click="delMuch(delarr)"
            class="delBtn"
            :type="typeInfo1"
            :disabled="btnDel"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </el-col>
    </el-row>
    <el-row
      :gutter="16"
      class="mt15"
      style="margin-left: 0px; margin-right: 2px"
    >
      <el-table
        style="width: 100%"
        :row-key="getRowKeys"
        size="small"
        stripe
        fit
        class="tableClass"
        tooltip-effect="dark"
        ref="multipleTable"
        :data="tableData"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'CREATE_TIME', order: 'descending' }"
        @select-all="selectAll"
        @selection-change="handleSelectionChange"
        @select="selectOne"
        @sort-change="sort"
      >
        <el-table-column
          type="selection"
          align="center"
          width="60"
          :selectable="selectEnable"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="FILE_NAME"
          width="180"
          :show-overflow-tooltip="true"
          :label="$t('AppManage.H5strengPage.fileName')"
          sortable="custom"
        >
          <template v-slot="{ row }">
            {{ row.fileName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="FILE_SIZE"
          :label="$t('AppManage.H5strengPage.size')"
          width="100"
          :show-overflow-tooltip="true"
          sortable="custom"
        >
          <template v-slot="{ row }">
            {{ $common.formatFlowSize(row.size) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('AppManage.H5strengPage.H5Reinforcement')"
          width="100"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <span v-if="row.status == 0">
              {{ $t("AppManage.H5strengPage.Unreinforced") }}
            </span>
            <span v-else-if="row.status == 1">
              {{ $t("AppManage.H5strengPage.Reinforced") }}
            </span>
            <span v-else-if="row.status == 2">
              {{ $t("AppManage.H5strengPage.Strengthening") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CREATOR"
          :label="$t('public.Section')"
          width="100"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CREATE_TIME"
          :label="$t('AppManage.H5strengPage.uploaded')"
          width="180"
          sortable="custom"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            {{ row.createTime | dateformat }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('public.Controls')">
          <template v-slot="{ row }">
            <span
              style="padding: 0px"
              circle
              class="action_icon"
              :disabled="row.status == 2"
              @click="fasten(row)"
            >
              <img
                src="@/images/icon_fasten.png"
                :title="$t('AppManage.H5strengPage.reinforce')"
                alt=""
              />
            </span>
            <span
              style="padding: 0px"
              circle
              class="action_icon"
              :disabled="row.status == 2"
              @click="downLoad(row)"
            >
              <img
                src="@/images/icon_download.png"
                :title="$t('public.Download')"
                alt=""
              />
            </span>
            <span
              style="padding: 0px"
              circle
              class="action_icon"
              :disabled="row.status == 2"
              @click="del(row)"
            >
              <img
                src="@/images/icon_del.png"
                alt=""
                :title="$t('public.Delete')"
              />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页-->
      <pagination
        style="margin-right: 10px"
        :page="page.currentPage"
        :limit="page.pageSize"
        :total="page.total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 保存成功提示组件 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="sendMsg"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <Eldialog
      :width="'450px'"
      @handleClose="handleClose"
      :title="$t('AppManage.H5strengPage.DownloadFiles')"
      :dialogVisible="downloadVisible"
    >
      <div
        style="display: flex; justify-content: space-around; margin-top: 50px"
      >
        <el-button type="primary" @click="PrimaryFile">{{
          $t("AppManage.H5strengPage.OriginalFile")
        }}</el-button>
        <el-button type="primary" @click="StrengtheningFile">{{
          $t("AppManage.H5strengPage.ReinforcementFile")
        }}</el-button>
      </div>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from "@/components/elDialog";
import naviGation from "@/components/hearder/index"; //面包屑
import pagination from "@/components/pagination/page.vue";
import dialogInfo from "@/components/promptMessage/index";

export default {
  components: {
    Eldialog,
    pagination,
    naviGation,
    dialogInfo,
  },
  data() {
    return {
      //上传
      up: {
        type: "protected",
        files: [""],
      },
      down: {
        //下载
        type: "protected",
        path: "/模板文档/加固证明模板.docx",
      },
      page: {
        currentPage: 1, //当前页码
        pageSize: 10, //每页显示条数
        searchFileName: "", //查询
        total: 0, //返回数据总条数
        orderColume: "CREATE_TIME", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      form: {
        fileList: [],
      },

      btnDel: true,
      delarr: [], //存放要删除的数据

      fileList: [], //上传文件参数

      filePath: "", //文件存储地址
      btnJiagu: true,
      typeInfo: "info", //加固按钮默认类型
      typeInfo1: "info", //删除按钮默认类型
      tableData: [], //表格绑定数据

      titleInfo: "", //弹框内容变量
      isDisabled: false, //确定按钮状态
      isCheckShow: false, // 全选弹框
      visibaelFlag: false, //控制弹出
      selectionArray: [], //加固中
      reinforceStatus: 0, //加固状态--默认未加固
      downloadVisible: false, //下载文件弹框
      //上传文件保存接口参数fileName12324.zip/h5Reinforce/test.zip
      uploadFileParams: {
        size: "",
        path: "", //（地址）
        fileName: "",
      },
      multipleSelection: [],
      groupFullNameShowByIndex: "",
    };
  },

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getStrengthening();
  },
  // 方法集合
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },

    //排序
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.getStrengthening();
    },
    //仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectEnable(row, rowIndex) {
      if (row.status == 2) {
        return false;
      } else {
        return true;
      }
    },
    //获取H5加固列表
    async getStrengthening() {
      var params = {
        currentPage: this.page.currentPage, //当前页
        pageSize: this.page.pageSize, //每页多少数据
        orderColume: this.page.orderColume, //排序字段
        orderRule: this.page.orderRule, //排序规则ASC、DESC
        fileName: this.page.searchFileName, //文件名称
        size: "", //文件大小
      };
      // console.log(params, "获取H5加固列表参数");
      const res = await this.$axios.post(
        "/httpServe/h5Reinforce/getDataInfo",
        params,
        true
      );
      console.log(res, "获取H5加固列表");
      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    //查询表格数据
    async onSearch() {
      var params = {
        size: "", //文件大小
        pageSize: this.page.pageSize, //每页多少数据
        orderRule: this.page.orderRule, //排序规则ASC、DESC
        orderColume: this.page.orderColume, //排序字段
        currentPage: this.page.currentPage, //当前页
        fileName: this.page.searchFileName, //文件名称
      };

      const res = await this.$axios.post(
        "/httpServe/h5Reinforce/getDataInfo",
        params,
        true
      );

      this.tableData = res.data.content;
      this.page.total = res.data.total;
      this.page.currentPage = 1;
    },
    //下载文件弹框
    handleClose() {
      this.downloadVisible = false;
    },
    //下载
    downLoad(row) {
      this.filePath = row.path;

      const str = row.path;
      const strCopy = str.split("/");
      strCopy[strCopy.length - 1] = "safe-" + strCopy[strCopy.length - 1];
      this.filePath_safe = strCopy.join("/");

      //已加固
      if (row.status == "1") {
        //弹框出现
        this.downloadVisible = true;
        //未加固
      } else if (row.status == "0") {
        //直接下载原始文件
        this.PrimaryFile();
      }
    },
    //下载原始文件
    async PrimaryFile() {
      var params = {
        type: "protected",
        path: this.filePath,
      };

      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        params,
        true
      );

      if (res.data == null) {
        this.$message({
          message: this.$t("AppManage.H5strengPage.OriginalFileEmpty"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        await this.$axios.downloadFile(res.data);
      }
      this.downloadVisible = false; //关闭选择下载弹框
    },
    //下载加固文件
    async StrengtheningFile() {
      var params = {
        type: "protected",
        path: this.filePath_safe,
      };

      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        params,
        true
      );

      if (res.data == null) {
        this.$message({
          message: this.$t("AppManage.H5strengPage.ReinforcementFileEmpty"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        await this.$axios.downloadFile(res.data);
      }

      this.downloadVisible = false; //关闭选择下载弹框
    },

    //多条删除
    delMuch(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var params = {
        ids: ids,
      };
      // console.log(params, "H5加固多条删除参数");

      this.$confirm(
        this.$t("AppManage.H5strengPage.sureDeleteSeleH5File"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/h5Reinforce/delete", params, true)
            .then(async () => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
              this.btnDel = true;
              this.typeInfo1 = "info";
              this.page.currentPage = 1;
              this.getStrengthening();
            })
            .catch(() => {
              this.$message({
                message: this.$t("AppManage.H5strengPage.SaveFailed"),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },
    //单个删除
    del(rows) {
      var params = {
        ids: [rows.id],
      };
      this.$confirm(
        this.$t("AppManage.H5strengPage.sureDeleteSeleH5File"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios
            .post("/httpServe/h5Reinforce/delete", params, true)
            .then(async () => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
              this.btnJiagu = true;
              this.typeInfo = "info";
              this.btnDel = true;
              this.typeInfo1 = "info";
              this.page.currentPage = 1;
              this.getStrengthening();
            })
            .catch(() => {
              this.$message({
                message: this.$t("AppManage.H5strengPage.SaveFailed"),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },
    //多条加固
    fastenMuch(obj) {
      this.typeInfo = "primary";
      this.btnJiagu = false;
      this.btnDel = false;
      this.typeInfo1 = "primary";
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var params = {
        ids: ids,
      };
      this.$confirm(
        this.$t("AppManage.H5strengPage.sureReinforceSeleH5File"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          obj.forEach((item) => {
            item.status = 2;
            console.log(item.status, "item.status");
            this.btnJiagu = true;
            this.typeInfo = "info";
            this.btnDel = true;
            this.typeInfo1 = "info";
          });
          const res = await this.$axios.post(
            "/httpServe/h5Reinforce/reinforce",
            params,
            true
          );

          if (res.data == 1 || res.data == 2) {
            this.$message({
              message: this.$t("AppManage.H5strengPage.ReinforceSuccessful"),
              type: "success",
              offset: 100,
            });
            this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
            this.getStrengthening();
            this.btnJiagu = true;
            this.typeInfo = "info";
            this.btnDel = true;
            this.typeInfo1 = "info";
          }
          if (res.data == 0) {
            this.$message({
              message: this.$t("AppManage.H5strengPage.ReinforceFailure"),
              type: "error",
              offset: 100,
            });
            this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
            this.getStrengthening();
            this.btnJiagu = true;
            this.typeInfo = "info";
            this.btnDel = true;
            this.typeInfo1 = "info";
          }
        })
        .catch(() => {
          return false;
        });
    },
    //单个加固
    fasten(row) {
      console.log(row.status, "row");
      var params = {
        ids: [row.id],
      };
      this.$confirm(
        this.$t("AppManage.H5strengPage.sureReinforceSeleH5File"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          //加固中
          row.status = 2;
          const res = await this.$axios.post(
            "/httpServe/h5Reinforce/reinforce",
            params,
            true
          );

          console.log(res.data, "加固");
          if (res.data == 1 || res.data == 2) {
            this.$message({
              message: this.$t("AppManage.H5strengPage.ReinforceFailure"),
              type: "error",
              offset: 100,
            });
          }
          if (res.data == 0) {
            this.$message({
              message: this.$t("AppManage.H5strengPage.ReinforceSuccessful"),
              type: "success",
              offset: 100,
            });
            this.getStrengthening();
            this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
          }
        })
        .catch(() => {
          return false;
        });
    },
    //上传文件

    async uploadChange(file) {
      let fileName = file.name;
      let suffix = file.name?.substring(fileName.lastIndexOf(".") + 1); //获取文件后缀名

      if (suffix != "zip") {
        this.$message({
          message: this.$t("AppManage.H5strengPage.FileFormatError"),
          type: "error",
          offset: 100,
        });
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", this.up.type);
        form.append("fileType", "zip");
        form.append("moduleName", "cer");
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        this.uploadFileParams.fileName = file.name;
        this.uploadFileParams.size = file.size;
        this.uploadFileParams.path = res.data.data[0];
        if (res.data.code == "200") {
          this.uploadFileSuccess1();
        }
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
    },
    async uploadFileSuccess1() {
      var params = this.uploadFileParams;

      const res = await this.$axios
        .post("/httpServe/h5Reinforce/save", params, true)
        .then(() => {
          this.$message({
            message: this.$t("AppManage.H5strengPage.FileUpSuccessfully"),
            type: "success",
            offset: 100,
          });
          this.getStrengthening();
          this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
        })
        .catch(() => {
          this.$message({
            message: this.$t("AppManage.H5strengPage.FileUploadFailed"),
            type: "error",
            offset: 100,
          });
        });
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getStrengthening();
    },

    selectOne(selection, row) {
      let selected = selection.length && selection.indexOf(row) !== -1;
      if (selected) {
        if (row.status == 2) {
          this.btnJiagu = true;
          this.typeInfo = "info";
          this.btnDel = true;
          this.typeInfo1 = "info";
        } else {
          this.typeInfo = "primary";
          this.btnJiagu = false;
          this.btnDel = false;
          this.typeInfo1 = "primary";
        }
      } else {
        this.btnJiagu = true;
        this.typeInfo = "info";
        this.btnDel = true;
        this.typeInfo1 = "info";
      }
    },
    //表格当选择项发生变化时会触发该事件
    handleSelectionChange(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      this.typeInfo1 = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
        this.typeInfo1 = "info";
      }
    },
    //selectAll勾选全选
    selectAll(selection) {
      console.log(selection, "selection");
      if (selection.length == 0) {
        this.btnJiagu = true;
        this.typeInfo = "info";
        this.btnDel = true;
        this.typeInfo1 = "info";
      } else if (selection.length >= 1) {
        this.typeInfo = "primary";
        this.btnJiagu = false;
        this.btnDel = false;
        this.typeInfo1 = "primary";
      }
    },

    // 关闭倒计时弹框
    sendMsg() {
      this.visibaelFlag = false;
    },
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.H5strengthening {
  //表格操作图片
  .action_icon {
    display: inline-block;
    height: 100% !important;
    img {
      margin-bottom: -4px !important;
    }
  }
  .mt15 {
    padding: 10px 10px 0px;
  }
}
</style>
<style lang="scss" scoped>
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
  display: none !important;
}
/deep/ #detailBox .el-dialog__body {
  height: 200px !important;
}
.upload-demo1 {
  .el-upload {
    .upBtn {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
}
.H5strengthening {
  ::v-deep .el-button-group .el-button--primary:last-child {
    border-left-color: rgba(255, 255, 255, 0.8) !important;
  }
}
</style>
